exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-ella-index-js": () => import("./../../../src/pages/about-ella/index.js" /* webpackChunkName: "component---src-pages-about-ella-index-js" */),
  "component---src-pages-about-us-air-pollution-js": () => import("./../../../src/pages/about-us/air-pollution.js" /* webpackChunkName: "component---src-pages-about-us-air-pollution-js" */),
  "component---src-pages-about-us-news-js": () => import("./../../../src/pages/about-us/news.js" /* webpackChunkName: "component---src-pages-about-us-news-js" */),
  "component---src-pages-about-us-our-campaigns-js": () => import("./../../../src/pages/about-us/our-campaigns.js" /* webpackChunkName: "component---src-pages-about-us-our-campaigns-js" */),
  "component---src-pages-about-us-our-people-js": () => import("./../../../src/pages/about-us/our-people.js" /* webpackChunkName: "component---src-pages-about-us-our-people-js" */),
  "component---src-pages-about-us-our-work-js": () => import("./../../../src/pages/about-us/our-work.js" /* webpackChunkName: "component---src-pages-about-us-our-work-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-get-involved-js": () => import("./../../../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-campaigns-js": () => import("./../../../src/templates/campaigns.js" /* webpackChunkName: "component---src-templates-campaigns-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */)
}

